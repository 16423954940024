body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 10px;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

section {
  padding: 20px;
  text-align: center;
}
