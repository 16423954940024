.contact-section {
    padding: 50px 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: white;
    padding-top: 5%;
  }
  
  .contact-section h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .contact-section p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .contact-info p {
    margin-bottom: 10px;
  }
  
  .contact-info a {
    color: #f39c12;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .contact-form {
    margin-top: 30px;
    text-align: left;
  }
  
  .contact-form h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #f39c12;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #e67e22;
  }
  